@import '../../styles/variables.scss';

body {
  background: $colorBg !important;
}

.logo {
  height: 31px;
  margin: 15px 0 22px 24px;
  float: left;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  background: transparent;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afafaf;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
  border-radius: 5px;
}
