$colorStroke: #d8e0ea;
$colorMenu: #4f5a60;
$colorSecond: #54657a;
$colorMain: #ed592a;
$colorMainDisable: #f27750;
$colorPlaceholder: #abb4bf;
$colorBlue: #0080dd;
$colorBlueDisable: #67baf6;
$colorBg: #f5f5f5;
$colorDisable: #f6f7f9;
$colorHoverDropdown: #f2f6fe;
$colorDefault: #253036;
$colorActiveMenu: #ffece8;
$colorGrey: #eaf9fb;
$colorDone: #39cd74;
$colorOrange: #f2994a;
$colorYellow: #f2c94c;
$colorBrownRed: #b43236;
$colorRed: #b3261e;
$colorTooltips: #1d2c3e;
$colorBgConversation: #151618;
$colorFb: #3b5998;
$colorIg: -webkit-linear-gradient(256.81deg, #8a2387 -0.65%, #e94057 48.09%, #f27121 99.59%);
$colorZalo: #0180c7;
$colorMellowGreen: #4bb6d8;
$colorSuccess: #2ebb7b;
$colorName: #f2f6fe;
$colorGreen: #16616a;
$colorIcon: #dadada;
$colorWhite: #fff;
$colorCyan: #2cc2d3;
$colorDarkCyan: #21929e;
$colorBlackLight: #131313;
$colorBlackLight2: #424242;
$colorGrayLight: #4d4849;
$colorGray2: #201a1b;
$colorGray3: #34303e;
$colorGray4: #dfdfdf;
$colorGray5: #f3f3f3;
$colorGray6: #242424;
$colorGray7: #e8ebec;
$colorGray8: #0e232c;
$colorGray9: #212b2f;
$colorRed1: #fffbf9;
$colorRed2: #b81e45;
$colorRed3: #c51b00;
$colorRed4: #fcebe7;
$colorGreen2: #18a800;
$colorGreen3: #e2fade;
