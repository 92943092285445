.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10em;

  @media all and (max-width: 480px) {
    padding-top: 50px;
  }
}

.bigImage {
  padding-bottom: 1.75em;

  img {
    width: 350px;
    height: 350px;
  }
}

.headline {
  padding: 0 0.25em 0.25em;
  font-size: 25px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.errorContent {
  font-size: 16px;
  padding: 0.25em;
}

.actionLink {
  cursor: pointer;
  color: #0080dd;
}
